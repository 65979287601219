import { useTranslation } from "react-i18next";
//import Masterpage from "@design-system/lumen/dist/Layout/Masterpage";
import { Masterpage } from "@design-system/lumen";
import CSMasterpage from "../../../components/CSMasterpage";

export default function ForgetPasswordPageFallback() {
    const { t } = useTranslation();


    return (
        <Masterpage
            breadcrumb={[
                {
                    pathname: '/',
                    title: t('Breadcrumb.AppTitle')
                },
                {
                    title: t('Breadcrumb.NotFound')
                }
            ]}
        >   
                
        </Masterpage>
    )
}