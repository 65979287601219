import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import { Overlay, HorizontalFlow, Dropdown, useNotificationsContext } from "@design-system/lumen";
import services from "../../../../services/generic/services";

import CancelActionConfimation from "../../../../components/parts/CancelActionConfimation";
import notificationsHelper from "../../../../utils/notificationsHelper";

import InviteType from "../../../../enum/InviteType";

function getCompanyUsers({ companyRoles, companyUsers, selectedUserId = 0 }) {
    // TODO: Filter this on server side
    // Filter just the admins
    return companyUsers?.filter(user => user.roles)
            .map(user => (
            {   
                selected:user.id === selectedUserId,   
                id: user.id,
                text: user.name ? user.name : '',
                user: user
            }));
}

export default function EditAccountHolder({ companyData, setCriticalError, overlayVisible, onClose, loadDataCallback }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')


    const [notificationsResign, setNotificationsResign] = useState('')
    const [notificationsResignType, setNotificationsResignType] = useState('')
    const [overlayResignVisible, setOverlayResignVisible] = useState(false)


    const [accountUsers, setAccountUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()

    useEffect(() => {
        setNotifications('')
        setNotificationsType('')
        setAccountUsers(getCompanyUsers({ companyRoles: companyData?.roles, companyUsers:companyData?.users, selectedUserId: companyData?.accountHolder?.id}))

    }, [companyData])

    async function save() {
        
        setIsLoading(true);

        const invite = {
            email: selectedUser?.email,
            companyId: companyData.companyId,
            type: InviteType.Holder,
            url: '/invites/accept/{0}/{1}'
        }

        const response = await services.sendInvite({
            tokenProvider: accessTokenProvider,
            companyId: companyData.companyId,
            data: invite
        });

        if (response.success) {
            setNotifications(t('Account.Company.CompanySection.EditAccountHolderEmail.Confirm.Message.Success'))
            setNotificationsType('success')

            addNotification(notificationsHelper.success({text:t('Account.Company.CompanySection.EditAccountHolderEmail.Confirm.Message.Success', {email: selectedUser?.email})}))
            loadDataCallback()
            onClose()
            setOverlayResignVisible(false)
        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 409 || response?.data?.status === 404) {
                    setNotifications(t('Account.Company.CompanySection.EditAccountHolderEmail.Confirm.Message.' + response?.data?.message))
                    setNotificationsType('error')
                } else {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    }

    function cancel() {
        setNotifications("")
        setNotificationsType("")
        onClose()
    }

    function save1() {

        editAccountHolder();
        setNotificationsResign("")
        setNotificationsResignType("")
        setOverlayResignVisible(true);
        onClose()
    }
    
    function cancelResign() {
        setOverlayResignVisible(false);
    }


    return (
        <>
            <Overlay
                actions={[
                    {
                        action: function () { save() },
                        title: t('Common.Actions.Save'),
                        type: 'primary',
                        active: true
                    },
                    {
                        action: function () { cancel(); },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                active={true}
                allowCloseWithActions={false}
                centerContentVertically={true}
                error={false}
                errorTemplate="Something went wrong. Please try again later."
                loading={isLoading}
                notifications={notifications}
                notificationsType={notificationsType}
                title={t('Account.Company.CompanySection.EditAccountHolderEmail.Title')}
                visible={overlayVisible}
                allowScroll ={false}
            >

                <HorizontalFlow
                    maxItemsPerLine="1"
                    useBottomMargin={false}
                    useTopMargin={false}
                >
                    <div>
                        <Dropdown
                            active={true}
                            buttonMode={false}
                            defaultValue={null}
                            labelText={t('Account.Company.CompanySection.EditAccountHolderEmail.Name')}
                            loading={isLoading}
                            noItemsLabel={t('Account.Company.CompanySection.EditAccountHolderEmail.NoUsers')}
                            numberOfVisibleOptions={6}
                            openPanelToLeft={false}
                            options={accountUsers}
                            searchLabel={t('Account.Company.CompanySection.EditAccountHolderEmail.searchLabel')}
                            searchable={true}
                            selectionCallback={item => setSelectedUser(item?.user)}
                        />
                    </div>
                     
                </HorizontalFlow>

            </Overlay>

            
            <CancelActionConfimation
                actions={[
                    {
                        action: function () { editAccountHolder() },
                        title: t('Common.Actions.Resign'),
                        type: 'ghost_semantic'
                    },
                    {
                        action: function () { cancelResign() },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                loading={false}
                title={t('Account.Company.CompanySection.EditAccountHolderEmail.Title')}
                notifications={notificationsResign}
                notificationsType={notificationsResignType}
                isVisible={overlayResignVisible}
                question={t('Account.Company.CompanySection.EditAccountHolderEmail.Confirm.Question')}
                message={t('Account.Company.CompanySection.EditAccountHolderEmail.Confirm.QuestionMessage', { user: selectedUser?.name ? selectedUser.name: '' })}
         
         >
            </CancelActionConfimation>
        </>
    )
}
