import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext, ListView, HorizontalFlow, InputSearch } from "@design-system/lumen";
import styled from '@emotion/styled';
import UserItem from "../../../../components/UserItem";
import InvitesSent from "./InvitesSent";
import EditUserPermissionsOverlay from "./EditUserPermitionOverlay";
import InviteUserOverlay from "./InviteUserOverlay";

function getCompanyUsers({ companyUsers, roles, t }) {
    return companyUsers?.map(user => (
        {
            active: true,
            child: <UserItem desc={""}
                name={user.roles?.some(role => role.name == 'Admin') ? t('PremiumAccounts.CardView.Admin'): t('PremiumAccounts.CardView.Member')} ></UserItem>,
            detailActionActive: true,
            id: 0,
            title: user.name,
            user: user,
            trailingActionActive: true
        }))
}

export default function Users({ isAccountHolder, isAdmin, companyData, invites, isLoading, maxUsers, setIsLoading, setCriticalError, loadDataCallback }) {
    const [, setSearchInput] = useState('')
    const [companyUsers, setCompanyUsers] = useState([])
    const { t } = useTranslation();
    const [overlayInviteVisible, setOverlayInviteVisible] = useState(false)
    const [overlayEditePermitionsVisible, setOverlayEditePermitionsVisible] = useState(false)
    const [userToEdit, setUserToEdit] = useState([])
    // const [{SearchCount}, ] = useState(PageComponents())


    useEffect(() => {
        setCompanyUsers(getCompanyUsers({
            companyUsers: companyData.users,
            roles: companyData.roles,
            t: t
        }))

    }, [companyData, t])

    // async function setSearchUsers({ text, companyData, t }) {

    //     var userCompanyFiltered = await companyData.users.filter(user =>
    //         user?.name?.toLowerCase().includes(text?.toLowerCase())
    //     );
    //     setCompanyUsers(getCompanyUsers({
    //         companyUsers: userCompanyFiltered,
    //         roles: companyData.roles,
    //         t: t
    //     }))
    // }

    function editUserPermitions({ item }) {
        setOverlayEditePermitionsVisible(true)

        setUserToEdit(item?.data?.user)
    }

    function addNewItem() {
        setOverlayInviteVisible(true);
    }

    return (
        <>
            <div>
                {/* <HorizontalFlow useBottomMargin={true} useTopMargin={false}>
                    <InputSearch
                        active={!isLoading}
                        onValueChange={function (item) { return setSearchUsers({ text: item, companyData: companyData, t: t }) }}
                        placeholder={t('Account.Users.SearchPlaceholder')}
                        setValue={setSearchInput}
                        useSearchButton={true}
                        width="100%"
                    />
                    <SearchCount isLoading={isLoading}>{t('Account.Users.UserCount', { count: companyUsers?.length })}</SearchCount>

                </HorizontalFlow> */}
                <ListView
                    active={true}
                    addNewItemCallback={() => { addNewItem(); }}
                    addNewItemPosition= {isAdmin?"first": "none" }
                    addNewItemText={t('Account.Users.InviteUser.Title')}
                    alternativeBackground={true}
                    detailAction={item => { editUserPermitions({ item: item }); }}
                    detailColumnGap=""
                    detailRowGap=""
                    itemTitleWidthProfile={[
                        '100vw',
                        '100vw',
                        '30vw',
                        '30vw',
                        '30vw'
                    ]}
                    listData={companyUsers}
                    loading={isLoading}
                    noResultsMessage={t('Account.Users.NoUsersFound')}
                    //leadingActionTitle={t('Account.Users.Licenses')}
                    //leadingActionIcon='list'
                    //leadingAction={(user) => console.log('Licenses button hit')} 
                    showLoadingWithActions={true}
                    dataTestId="listMemberInvites_listMembers"
                />

                <InvitesSent
                    visible = {isAdmin}
                    invites={invites}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setCriticalError={setCriticalError}
                    loadDataCallback={loadDataCallback}>
                </InvitesSent>

            </div>
            <InviteUserOverlay key={0} companyData={companyData}
                maxUsers={maxUsers}
                visible={overlayInviteVisible}
                invites ={invites.filter(invite => invite.inviteStatus == "Sent" && invite.companyRoles.length != 0).length}
                companyUsers ={companyUsers?.length}
                setCriticalError={setCriticalError}
                onClose={() => setOverlayInviteVisible(false)}
                loadDataCallback={loadDataCallback}
            ></InviteUserOverlay>

            <EditUserPermissionsOverlay key={1} companyData={companyData}
                visible={overlayEditePermitionsVisible}
                isCurrentUserAccountHolder={isAccountHolder}
                isCurrentUserAdmin={isAdmin}
                userToEdit={userToEdit}
                setCriticalError={setCriticalError}
                onClose={() => setOverlayEditePermitionsVisible(false)}
                loadDataCallback={loadDataCallback}
            ></EditUserPermissionsOverlay>
        </>
    )
}

// function PageComponents(){

//     const { theme } = useThemeContext()
//     const {  density_space_xs } = theme
//     const SearchCount = styled.div`
//         ${font_md};
//         padding: ${density_space_xs};
//         display: ${p => p.isLoading ? 'none': 'block'}
//     `
//     return { SearchCount }
// }