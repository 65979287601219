import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useThemeContext, ListView, HorizontalFlow, InputSearch, Text, useAvailableWidth, Button } from "@design-system/lumen";
//import Button from "@design-system/lumen/dist/Buttons/Button";
import { useMemo } from "react";
import PremiumFeaturesTabPageStyle from "./PremiumFeaturesTabPageStyle";
import ProgressBar from "../../../../components/ProgressBarComponent";
import services from '../../../../services/generic/services';
import accessTokenProvider from '../../../../services/generic/session'
import { useCallback } from 'react';



export default function PremiumFeatures({ isAccountHolder, isAdmin, companyData, invites, isLoading, setIsLoading, setCriticalError, loadDataCallback }) {
    const [, setSearchInput] = useState('')
    const [premiumFeatures, setPremiumFeatures] = useState([])
    const { t } = useTranslation();
    const { theme } = useThemeContext();
    const { EmptyDiv } = useMemo(() => PremiumFeaturesTabPageStyle(theme), [theme]);
    const [planName, setPlanName] = useState("");
    const [productName, setProductName] = useState("");
    const {
        breakpoint //string, current breakpoint for the viewport, can be: xs, sm, md, lg, xl
    } = useAvailableWidth();
    useEffect(() => {
        async function fetchPremiumData() {
            // get Companydata
            if(companyData.length != 0)
            {
                const response = await services.getPlanFeatureByUser({ tokenProvider: accessTokenProvider, planId: 5, companyId: companyData.companyId })
            
                if (response.success) {
                    setPremiumFeatures(response?.data?.features);
                    setPlanName(response?.data?.name);
                    setProductName(response?.data?.productName);
                    debugger
                } else {
                    if (response.data.isActionError) {

                        if (response.data.status === 404) {
                            response.data.message = 'Account.Message.NotFound'
                            setCriticalError(response.data)
                            setIsLoading(false)
                            return
                        }
                        setCriticalError(response.data)
                    } else {
                        setCriticalError(response.data)
                    }
                }
                setIsLoading(false)
            }

        }
        fetchPremiumData();
    }, [companyData])


    const getListData = useCallback(
        (features, enable) => {
            let barWidth = "100px"
            switch (breakpoint) {
                case 'xs':
                    barWidth = "100px"
                    break;
                case 'sm':
                    barWidth = "200px"
                    break;
                case 'md':
                    barWidth = "300px"
                    break;
                case 'lg':
                    barWidth = "400px"
                    break;
                case 'xl':
                    barWidth = "500px"
                    break;
                default:
                    break;
            }

            return features
                .map(feature => (
                    {
                        active: enable,
                        child: <ProgressBar barWidth={barWidth} completed={(feature.used / feature.value) * 100} labelDesc={t('Plan.Features.' + feature.featureName + "Count", { count: feature.used + " of " + feature.value })} />,
                        detailActionActive: true,
                        id: 0,
                        title: t('Plan.Features.' + feature.featureName),
                        trailingActionActive: true
                    }));
        },
        [breakpoint, t],
    )


    // async function setSearchUsers({ text, companyData, t }) {

    //     var userCompanyFiltered = await companyData.users.filter(user =>
    //         user?.name?.toLowerCase().includes(text?.toLowerCase())
    //     );
    //     setCompanyUsers(getCompanyUsers({
    //         companyUsers: userCompanyFiltered,
    //         roles: companyData.roles,
    //         t: t
    //     }))
    // }

 
    function editeUserPermitions({ item }) {
        setOverlayEditePermitionsVisible(true)

        setUserToEdit(item?.data?.user)
    }

    function addNewItem() {
        setOverlayInviteVisible(true);
    }
    return (
        <>
            <div>

                <HorizontalFlow
                    useBottomMargin={true}
                    maxItemsPerLine="4"
                    useTopMargin={false}
                    width="100%">
                    <div>
                    <div>
                        <Text
                            style={{fontSize:"30px", paddingBottom:"10px" }}  
                            text={planName}
                        > </Text>
                    </div>
                    <div >
                        <Text
                            style={{paddingTop:"10px"}}  
                            text={productName}
                        ></Text>
                    </div>
                    </div>
                    <EmptyDiv />
                    <EmptyDiv />
                    {/* <Button
                        active={isAccountHolder}
                        loading={isLoading}
                        leadingIcon="pencil"
                        type='primary'
                        text={t('Account.Company.PremiumFeaturesSection.Subscription.Title')}
                        style={{ marginLeft: 'auto' }}
                        onClick={() => setOverlayEditVisible(true)} /> */}
                </HorizontalFlow>
                <ListView
                    active={true}
                    addNewItemCallback={() => { addNewItem(); }}
                    addNewItemPosition={"none"}
                    alternativeBackground={true}
                    //detailAction={item => { editeUserPermitions({ item: item }); }}
                    detailColumnGap=""
                    detailRowGap=""
                    itemTitleWidthProfile={[
                        '100vw',
                        '100vw',
                        '30vw',
                        '30vw',
                        '30vw'
                    ]}
                    listData={getListData(premiumFeatures, companyData.enabled)}
                    loading={isLoading}
                    noResultsMessage={t('Account.Users.NoUsersFound')}
                    //leadingActionIcon='list'
                    //leadingAction={(user) => console.log('Licenses button hit')}
                    showLoadingWithActions={true}
                />
            </div>

        </>
    )
}

// function PageComponents(){

//     const { theme } = useThemeContext()
//     const {  density_space_xs } = theme
//     const SearchCount = styled.div`
//         ${font_md};
//         padding: ${density_space_xs};
//         display: ${p => p.isLoading ? 'none': 'block'}
//     `
//     return { SearchCount }
// }