import React from "react";
import styled from "@emotion/styled";
import { useMemo } from "react";
import { useThemeContext } from "@design-system/lumen"
import {getFontMD} from "@design-system/lumen/dist/styles/classes";

export default function ProgressBar({ barWidth="500px", completed, labelDesc }) {

  const { theme } = useThemeContext();
  const font_md = getFontMD(theme);
  const { ProgressContainer, ProgressBar, ProgressLabel, Label, ContainerStyles, Filler } = useMemo(() => UserItemStyle(theme), [theme]);

  return (
    <>
      <ProgressContainer>
        <ProgressBar barWidth={barWidth}> 
            <Filler completed={completed} />
        </ProgressBar>
        <ProgressLabel>
          <Label>{`${labelDesc}`}</Label>
        </ProgressLabel>
      </ProgressContainer>
    </>
  )
}




function UserItemStyle(theme) {

    const { color_ambient_05, color_brand_primary_02, color_brand_primary_03, color_brand_primary_04 } = theme;
    const font_md = getFontMD(theme);
  const Filler =  styled.div`
    height: 100%;
    transition: all 1s ease-in-out;
    border-radius: inherit;
    background-color: ${color_brand_primary_03};
    width: ${p => p.completed}%;
    ::after {
      width: 0%;
      content: ' ';
    }
  `

  const ProgressContainer = styled.div`
      color: ${color_ambient_05};
      ${font_md};
      margin-top: ${props => (props.useMargins ? '12px' : '0')};
      margin-bottom: ${props => (props.useMargins ? '24px' : '0')};
      width: 50%;
     `
  const ProgressBar = styled.div`
      display: inline-flex;
      width: ${p => p.barWidth};
      height: 7px;
      background-color: ${color_brand_primary_04};
      border-radius: 4px;
      margin: 7px;
      `
  const ProgressLabel = styled.div`
      display: inline-flex;

      `
  const Label = styled.div`
      color: ${color_brand_primary_02};
      padding: 5px;
      fontWeight: bold;
      `
  return { ProgressContainer, ProgressBar, ProgressLabel, Label, Filler }
}

