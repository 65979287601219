import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    HorizontalFlow,
    Button,
    DataBlocks,
    useNotificationsContext,
    CardView
} from "@design-system/lumen";
import EditName from "./EditName";
//import CardView from "@design-system/lumen/dist/Listings/CardView";
import DeletePremiumAccount from "./DeletePremiumAccount";
import EditAccountHolder from "./EditAccountHolder";
import accessTokenProvider from '../../../../services/generic/session'
import features from "../../../../utils/getPlanFeatures";
import EditBillingInfo from "./EditBillingInfo";
import CancelSubscription from "./CancelSubscription";
import services from "../../../../services/generic/services";
import CompanyPageStyle from "./CompanyPageStyle";
import {
    useThemeContext
} from '@design-system/lumen'


export default function Company({ isAdmin, companyData, isLoading, setIsLoading, setCriticalError, loadDataCallback }) {
    const { t } = useTranslation();
    const [overlayEditNameVisible, setOverlayEditNameVisible] = useState(false)
    const [overlayEditVisible, setOverlayEditVisible] = useState(false)
    const [showEditBillingInfo, setShowEditBillingInfo] = useState(false)
    const [editBillingInfoUrl, setEditBillingInfoUrl] = useState(null)
    const [showCancelSubscription, setShowCancelSubscription] = useState(false)
    const [cancelSubscriptionUrl, setCancelSubscriptionUrl] = useState(null)
    const [overlayDeletPremiumVisible, setOverlayDeletPremiumVisible] = useState(false)
    const [plans, setPlans] = useState([]);
    const { addNotification } = useNotificationsContext()
    const {theme} = useThemeContext();
    const [{  PlanContainer, Title, DeletePremiumAccountSection, ActionButton, NoPaddingScreenData },] = useMemo(() => CompanyPageStyle(theme), [theme]);
    const {companyId} = companyData;
    
    useEffect(() => {
        features.planByPlanId({
            planId: 3,
            setIsLoading: (val) => setIsLoading(val),
            setPlans: (val) => setPlans(val),
            detailActionActive: false,
            leadingActionActive: true,
            addNotification: (val) => addNotification(val),
            setCriticalError: (val) => setCriticalError(val),
            t: t,
            isLoggdIn: true,
            tokenProvider: accessTokenProvider
        })
        setShowCancelSubscription(false)
        setShowEditBillingInfo(false)
    }, [addNotification, t, setCriticalError])

    useEffect(() => {
        async function fetchSubscriptionManagementLinks() {
            setIsLoading(true);
            let response = await services.getSubscriptionManagementLinks({ tokenProvider: accessTokenProvider, companyId: companyId })
            if (response.success) {
                setEditBillingInfoUrl(response.data?.updateUrl);
                setCancelSubscriptionUrl(response.data?.cancelUrl);
            } else {
                //handleWithError(response?.data)
            }

            setIsLoading(false)
        }

        fetchSubscriptionManagementLinks();
        
    }, []);

    return (
        <>
            <div>
                <HorizontalFlow useBottomMargin={false} useTopMargin={false} maxItemsPerLine={2}>
                    <NoPaddingScreenData>
                        <DataBlocks active={true} 
                            alternativeBackground={false}
                            data={[
                                {
                                    data: [
                                        {
                                            label: t('Account.Company.CompanySection.Name'),
                                            value: companyData.name,
                                        },
                                        {
                                            label: t('Account.Company.CompanySection.AccountHolderEmail'),
                                            value: companyData.accountHolder?.email,
                                        },
                                    ],
                                }
                            ]}
                            columnsPerBreakpoint={[1,1,1,1,1]}
                            labelWidth="25%"
                            loading={isLoading}
                            sectionButtonCallback={function (section) { return console.log(section); }}
                        />
                    </NoPaddingScreenData>

                    <ActionButton>
                        <Button active={isAdmin}
                            leadingIcon='pencil'
                            text={t('Account.Company.CompanySection.EditName.Title')}
                            onClick={() => setOverlayEditNameVisible(true)}>
                        </Button>
                        <Button active={isAdmin}
                            leadingIcon='pencil'
                            text={t('Account.Company.CompanySection.EditAccountHolderEmail.Title')}
                            onClick={() => setOverlayEditVisible(true)}>
                        </Button>
                    </ActionButton>
                </HorizontalFlow>
                <Title>{t('Account.Company.SubscriptionSection.Title')}</Title>
                <HorizontalFlow useBottomMargin={false} useTopMargin={false} maxItemsPerLine={2}>
                    <PlanContainer>
                        {<CardView
                            active={isAdmin}
                            addNewItemPosition="none"
                            loading={isLoading}
                            cardsData={plans}
                            alternativeBackground={true}
                            numberOfColumnOverride={1}
                            leadingAction={item => { console.log("2", item); }}
                            leadingActionTitle={t('Account.Company.SubscriptionSection.Upgrade')}
                        />}
                    </PlanContainer>
                    <ActionButton>
                        <Button active={isAdmin}
                            leadingIcon='pencil'
                            text={t('Account.Company.SubscriptionSection.EditBillingInfo.Title')}
                            onClick={() => setShowEditBillingInfo(true) /* handlePaddle('https://sandbox-checkout.paddle.com/subscription/update?user=41825&subscription=98737&hash=df6f3c538b29c7e75d62a292e247cb582af4b8e0c120ab9ac5f64410db34dcfd')*/}>
                        </Button>
                        <Button active={isAdmin}
                            leadingIcon='pencil'
                            text={t('Account.Company.SubscriptionSection.CancelSubscription.Title')}
                            onClick={() => setShowCancelSubscription(true)  /* handlePaddle('https://sandbox-checkout.paddle.com/subscription/cancel?user=41825&subscription=98737&hash=dcac5933d5d3eaf43329a0608b5abe1a95e722e32a25ca9365a55740f2daed0e')*/}>
                        </Button>
                    </ActionButton>
                </HorizontalFlow>

                <DeletePremiumAccountSection>
                    <ActionButton>
                        <Button active={isAdmin}
                            text={t('Account.Company.DeletePremiumAccountSection.Title')}
                            type='ghost_semantic'
                            onClick={() => setOverlayDeletPremiumVisible(true)}
                            style={{marginLeft:'auto'}}>
                        </Button>
                    </ActionButton>
                </DeletePremiumAccountSection>
            </div>

            <EditName key={0} companyData={companyData}
                visible={overlayEditNameVisible}
                setCriticalError={setCriticalError}
                onClose={() => setOverlayEditNameVisible(false)}
                loadDataCallback={loadDataCallback}
            ></EditName>


            <EditAccountHolder key={1} companyData={companyData}
                visible={overlayEditVisible}
                setCriticalError={setCriticalError}
                onClose={() => setOverlayEditVisible(false)}
                loadDataCallback={loadDataCallback}
            ></EditAccountHolder>


            <DeletePremiumAccount key={2}
                companyData={companyData}
                overlayVisible={overlayDeletPremiumVisible}
                onClose={() => setOverlayDeletPremiumVisible(false)}
                loadDataCallback={loadDataCallback}
            ></DeletePremiumAccount>

            <EditBillingInfo key={3}
                actionName={t('Account.Company.SubscriptionSection.EditBillingInfo.Title')}
                visible={showEditBillingInfo}
                onClose={() => setShowEditBillingInfo(false)}
                loadDataCallback={loadDataCallback}
                url={editBillingInfoUrl} //'https://sandbox-checkout.paddle.com/subscription/update?user=41825&subscription=98738&hash=716eccdff23b7a05deeae60492e312f6dd42fecab7579ace6d3f617fc1c309a9'
            ></EditBillingInfo>
        
            <CancelSubscription key={4}
                actionName={t('Account.Company.SubscriptionSection.CancelSubscription.Title')}
                visible={showCancelSubscription}
                onClose={() => setShowCancelSubscription(false)}
                loadDataCallback={loadDataCallback}
                url={cancelSubscriptionUrl} //'https://sandbox-checkout.paddle.com/subscription/cancel?user=41825&subscription=98738&hash=dab7c4d17d340c2121fa8b98641683f9d471c360677378e7e157541e3cb36cfc'
            ></CancelSubscription>
        </>
    )
}