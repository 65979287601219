

/* eslint-disable react/prop-types */
import {  useState } from "react";
import styled from '@emotion/styled'
import {
    useThemeContext,
    Svg,
    xs,
    sm_or_larger,
} from '@design-system/lumen'
import {getFontMD} from "@design-system/lumen/dist/styles/classes";



function PageComponents() {


    const { theme } = useThemeContext()
    const { density_space_lg, density_space_md, color_ambient_02, color_critical_base } = theme
    const font_md = getFontMD(theme)

    const DeleteConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

    const Mobile = styled.div`
    ${xs} { 
        display: flex; 
    }
    ${sm_or_larger} {
        display: none;
    }
    flex-direction: column;
    align-items:center;
    gap: ${density_space_lg};
`

    const Desktop = styled.div`
    ${xs} { 
        display: none; 
    }
    ${sm_or_larger} {
        display: flex;
    }

    flex-direction: row;
    gap: ${density_space_md};
`

    const Question = styled.div`
    ${font_md}
    color: ${color_ambient_02}
`
    const Warning = styled.div`
    ${font_md}
    color: ${color_critical_base};
`
    const Contents = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${density_space_md};
    justify-content: center;
`


    return { Contents, Warning, Question, Desktop, Mobile, DeleteConfirmationContainer }
}
export default function DeleteConfirmation({ question, warning }) {

    const { theme } = useThemeContext();


    const [{Contents, Warning, Question, Desktop, Mobile, DeleteConfirmationContainer }, ] = useState(PageComponents())

    const { color_critical_base } = theme

    return (
        <DeleteConfirmationContainer data-testid="delete-confirmation">
            <Mobile>
                <Svg icon="hand" style={{ width: '96px', height: '96px' }} pathStyle={{ fill: color_critical_base }}></Svg>
                <Contents>
                    <Question>{question}</Question>
                    <Warning>{warning}</Warning>
                </Contents>
            </Mobile>
            <Desktop>
                <Svg icon="hand" style={{ width: '96px', height: '96px' }} pathStyle={{ fill: color_critical_base }}></Svg>
                <Contents>
                    <Question>{question}</Question>
                    <Warning>{warning}</Warning>
                </Contents>
            </Desktop>
        </DeleteConfirmationContainer>
    )
}
