import React, { useEffect, useLayoutEffect, useState, useRef, useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getComponentSize, font_sm } from "@design-system/lumen/dist/styles/classes";
// import Svg from '../Svg'
import InputStyle from './InputStyleV2'

import {
    useThemeContext,
    Svg,
    Tooltip,
    TextWithIcon,
    // InputStyle
  } from "@design-system/lumen";
 

// import TextWithIcon from '../Texts/TextWithIcon'
// import { useThemeContext } from '../providers/ThemeProvider'
// import ShowHidePasswordIcon from './ShowHidePasswordIcon'
// import Console from '../Console'

 

export default function InputV2({
  type = 'text',
  width: widthDefault = '100%',
  widthInColumns,
  name = null,
  label = null,
  labelText = null, //this will be removed in the future, use "label" prop instead
  placeHolder = '', //this will be removed in the future, use "placeholder" prop instead
  placeholder = null,
  disabled = false, //will be removed in the future, use "active" prop
  active = true,
  loading = false,
  value = '',
  setValue,
  children, //will be removed in the future, does not make sense
  dataTestId = null,
  helperText = null,
  maxLength = null,
  onKeyDown = null,
  onBlur,
  forceValidations = false, //fires all validations immediatly not only on blur
  showValidationError = false,
  validationRegExpString = null,
  validationErrorMessage = null,
  validationCallback = null,
  trailingIcon = null,
  trailingIconColor,
  foccused = false,
  required = false,
  requiredMessage = 'Mandatory field.',
  marginTop = '0px',
  marginBottom = '0px',
  marginLeft = '0px',
  marginRight = '0px',
  debug = false,
  allowClear = true,
  min = 0,
  max = 100,
  step = 1,
  unit,
  onMouseUp,
  onMouseDown,
  autoFocus = undefined,
  selectAllOnFocus = false, //selects all text when focus
  borderColor=null, //overrides default border color, default color_ambient_06
  borderColorHover=null, //overrides default hover border color, default color_ambient_02
  borderColorFocus=null, //overrides default hover border color, default color_ambient_02
}) {
  const inputRef = useRef(null)

  const [internalId, setInternalId] = useState()
  const [internalPlaceholder, setInternalPlaceholder] = useState('')
  const [internalActive, setInternalActive] = useState(true)
  const [internalText, setInternalText] = useState('')
  const [internalLabel, setInternalLabel] = useState(null)

  const [showingPassword, setShowingPassword] = useState(false)

  const [width, setWidth] = useState(widthDefault)
  const [focus, setFocus] = useState(false)
  const [mouseOverResetIcon, setMouseOverResetIcon] = useState(false)
  const [isInputValid, setIsInputValid] = useState(true)
  const [internalType, setInternalType] = useState('text')

  const [startedValidation, setStartedValidation] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')


  const { theme } = useThemeContext()
  const { color_ambient_05, density_space_3xs, density_space_xxs, color_critical_base, density_space_xs, density_space_sm } = theme

  const { Container, TextInput, Label, HintContainer, ResetIcon, InputWrapper, RangeValue } = useMemo(() => InputStyle(theme), [theme])

  const log = useCallback(
    (message, obj) => {
      if (debug) { Console.log(message, obj) }
    },
    [debug],
  )


  useEffect(() => {
    if (internalType !== type) {
      if (internalType) {
        log('setting type to:', type);
        setInternalType(type)
      } else {
        log('setting type to default "text"')
        setInternalType('text')
      }
    } else {
      //console.log('same input type... skipping...')
    }
  }, [type, internalType, log])

  //used as an intermediate step to delete "placeHolder" 
  //trying to standarize "placeholder" property
  useEffect(() => {
    if (placeholder !== null && placeholder !== undefined) {
      setInternalPlaceholder(placeholder)
    } else {
      setInternalPlaceholder(placeHolder)
    }
  }, [placeHolder, placeholder])

  //used as an intermediate step to delete "labelText" 
  //trying to standarize "label" property
  useEffect(() => {
    if (label !== null && label !== undefined) {
      setInternalLabel(label)
    } else {
      setInternalLabel(labelText)
    }
  }, [label, labelText])

  //used as an intermediate step to delete "disabled" 
  //trying to standarize "active" property
  useEffect(() => {
    if (disabled || !active || loading) {
      setInternalActive(false)
    } else {
      setInternalActive(true)
    }
  }, [disabled, active, loading])

  useEffect(() => {
    if (value === null || value === undefined) { setInternalText('') }
    else { setInternalText(value) }
  }, [value])


  useEffect(() => {
    if (foccused) {
      inputRef.current.focus()
    }
  }, [foccused])

  useLayoutEffect(() => {
    if (autoFocus) {

      if (selectAllOnFocus) {
        setTimeout(() => {
          inputRef.current.select()
        }, 50)

      } else {
        inputRef.current.focus()
      }
    } else if (selectAllOnFocus) {
      setTimeout(() => {
        inputRef.current.select()
      }, 50)
    }
  }, [autoFocus, selectAllOnFocus])

  const emailRegEx = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'

  //validations content
  useEffect(() => {

    try {
      //require validation
      if (required && internalText.trim() === '') {
        const message = requiredMessage ? requiredMessage : 'Mandatory field.';
        setValidationMessage(message)
        setIsInputValid(false)
        validationCallback(false, message)
      }
      else {

        let valid = true;
        let message = null;

        //default error messages per type
        if (validationErrorMessage) {
          message = validationErrorMessage;
        }
        else {
          switch (internalType) {
            case 'email':
              message = 'Please enter a valid email address.';
              break;
            default:
              message = 'Input is not valid.'
              break;
          }
        }

        //validations per type
        if (internalType === 'email') {
          if (!internalText.includes('@')) {
            //specific validationr requested by uxui team
            message = 'The address must contain an @ symbol.';
            valid = false;
          } else {
            //generic email validation (usually validationRegExpString should be empty unless testing for speciifc patterns)
            valid = new RegExp(validationRegExpString ? validationRegExpString : emailRegEx).test(internalText)
          }
        } else if (validationRegExpString) {
          //generic reg ex validation (fallback when no specific type is selected)
          valid = new RegExp(validationRegExpString).test(internalText)

        } else {
          valid = true;
        }

        setIsInputValid(valid);
        setValidationMessage(message);

        if (validationCallback) {
          validationCallback(valid, valid ? '' : message)
        }
      }
    } catch (error) {
      log('error validating:', error)
    }
  }, [internalType, internalText, validationRegExpString, validationErrorMessage, required, requiredMessage, validationCallback, log])

  useEffect(() => {
    if (name) {
      setInternalId(name)
    } else {
      setInternalId(uuidv4())
    }
  }, [name])

  useLayoutEffect(() => {

    function updateWidth() {
      if (widthInColumns) {
        const w = getComponentSize(window.innerWidth, widthInColumns)
        w ? setWidth(w) : log('Invalid widthInColumns property', widthInColumns)
      } else {
        setWidth(widthDefault)
      }
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [widthInColumns, widthDefault, log])

  return (
    <Container marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight} width={width}>
      {internalLabel ? (
        <Label htmlFor={name} disabled={!internalActive} focus={focus} onClick={() => inputRef.current.focus()}
        >
          {internalLabel}
        </Label>
      ) : null}

      <InputWrapper type={type} >
        <TextInput
          borderColor={borderColor}
          borderColorHover={borderColorHover}
          borderColorFocus={borderColorFocus}
          data-testid={dataTestId}
          focus={focus}
          allowClear={allowClear}
          type={internalType !== 'password' ? internalType : showingPassword ? 'text' : 'password'}
          id={internalId}
          name={name}
          maxLength={maxLength}
          ref={inputRef}
          disabled={!internalActive}
          placeholder={internalPlaceholder}
          value={internalText}
          autocomplete="off"
          hasError={(startedValidation || forceValidations) && !isInputValid}
          onChange={({ target: { value } }) => {
            setInternalText(value)
            if (setValue) {
              setValue(value)
            }
          }}
          onFocus={(event) => {
            setFocus(true)
            if (selectAllOnFocus) {
              event.target.select()
            }
          }}
          onBlur={() => {
            if (internalText && internalText.length > 0) {
              setStartedValidation(true)
            }

            if (!mouseOverResetIcon) {
              setFocus(false)
            }

            if (onBlur) {
              onBlur()
            }

          }}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          step={step}
          onMouseUp={(e) => {
            if (onMouseUp) {
              onMouseUp(e)
            }
          }}
          onMouseDown={(e) => {
            if (onMouseDown) {
              onMouseDown(e)
            }
          }}
        />

        {!internalText.length > 0 && trailingIcon ?
          <Svg
            icon={trailingIcon}
            style={{ position: 'absolute', top: density_space_xs, right: density_space_xs, width: density_space_sm, height: density_space_sm }}
            pathStyle={{ fill: trailingIconColor || color_ambient_05 }}
          /> : null
        }
        {internalType === 'password' && internalText.length > 0 ?
          <ShowHidePasswordIcon switchTypeFn={() => setShowingPassword(x => !x)} />
          :
          internalText?.length >= 1 && internalActive && focus && allowClear && type !== 'range' ?
            <ResetIcon
              onClick={() => {
                setInternalText('')
                if (setValue) {
                  setValue('')
                }
                setMouseOverResetIcon(false)
                inputRef.current.focus()
              }}
              onMouseOver={() => setMouseOverResetIcon(true)}
              onMouseLeave={() => setMouseOverResetIcon(false)}>
              <Svg icon="clear" />
            </ResetIcon> : null}

        {type === 'range' ?
          <RangeValue centerPercentage={(internalText - min) * 100 / (max - min)} centerOffset={12 * ((internalText - min) / (max - min) - 0.5)} >{internalText}{unit}</RangeValue> : null}
      </InputWrapper>

      {showValidationError && (startedValidation || forceValidations) && !isInputValid ?
        <TextWithIcon icon="critical" text={validationMessage}
          style={{ marginTop: density_space_3xs, gap: density_space_xxs }}
          iconColor={color_critical_base}
          textColor={color_critical_base}
          textStyle={font_sm}></TextWithIcon>
        : helperText ? <HintContainer >{helperText}</HintContainer> : null}

      {/* this is going to be deleted in near future, does not make sense */}
      {children ? <HintContainer >{children}</HintContainer> : null}

    </Container>
  )
}
