
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationsContext, CardView } from "@design-system/lumen";
//import CardView from "@design-system/lumen/dist/Listings/CardView";
import features from "../utils/getPlanFeatures";

export default function PlansFeatures({ detailActionPlan, isLoading, setIsLoading, setCriticalError,
    isActive = true,
    alternativeBackground = false, 
    tokenProvider,
    isLoggdIn,

}) {
    const { t } = useTranslation();
    const [plans, setPlans] = useState([]);
    const { addNotification } = useNotificationsContext()

    useEffect(() => {
           async function x() { features.plans({
                setIsLoading: (val) =>  setIsLoading(val) ,
                setPlans: (val) => setPlans(val) ,
                addNotification: (val) =>  addNotification(val) ,
                //setCriticalError: (val) =>  setCriticalError(val) ,
                setCriticalError: (val) => { console.log(val)  },
                t: t,
                tokenProvider :tokenProvider,
                isLoggdIn: false     
            })
        }

        x()
   

    }, [ ])

    return (
        <>
        <CartContainer>
            <CardView
                active={isActive}
                addNewItemPosition="none"
                loading={isLoading}
                detailAction={item => { detailActionPlan({ item: item }); }}
                cardsData={plans}
                alternativeBackground={alternativeBackground}
            />
            </CartContainer>
        </>
    )
}

const CartContainer = styled.div`
border-bottom: double;
`

