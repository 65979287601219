import styled from '@emotion/styled';
import { sm_or_larger, useThemeContext, xs } from "@design-system/lumen";
import {getFontLG, getFontMD} from "@design-system/lumen/dist/styles/classes";

export default function ErrorTemplateStyle() {

    const { theme } = useThemeContext()
    const { density_space_lg, density_space_md, color_ambient_02 } = theme
    const font_lg = getFontLG(theme)
    const font_md = getFontMD(theme)

    const Error = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
    `

    const Mobile = styled.div`
        ${xs} { 
            display: flex; 
        }
        ${sm_or_larger} {
            display: none;
        }
        flex-direction: column;
        align-items:center;
        gap: ${density_space_lg};
    `

    const Desktop = styled.div`
        ${xs} { 
            display: none; 
        }
        ${sm_or_larger} {
            display: flex;
        }
    
        flex-direction: row;
        gap: ${density_space_md};
    `

    const Title = styled.div`
        ${font_lg};
        color: ${color_ambient_02};
    `
    const Message = styled.div`
        ${font_md};
        color: ${color_ambient_02};
    `

    const Action = styled.div`
        margin-top: 20px;
    `

    const Contents = styled.div`
        display: flex;
        flex-direction: column;
        gap: ${density_space_md};
    `

    return { Contents, Action, Error, Message, Title, Desktop, Mobile }
}