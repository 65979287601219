import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import accessTokenProvider from '../../../../services/generic/session'
import services from "../../../../services/generic/services";
import { useNotificationsContext } from "@design-system/lumen";
import permition from "../../../../services/generic/permition";
import Company from "./Company";
import CompanyTabs from "../../../../enum/CompanyTabs";
import CompanyMasterpage from "../../../../components/CompanyMasterpage";

// deprecated - to be deleted in the future
export default function CompanyTabPage() {

    const location = useLocation();
    const companyNameParam = location.state?.companyName;

    const [isLoading, setIsLoading] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [criticalError, setCriticalError] = useState('')
    const [subscriptionTabVisible, setSubscriptionTabVisible] = useState(false);
    const [companyData, setCompanyData] = useState(null)
    const { companyId } = useParams()
    const { addNotification } = useNotificationsContext()
    const [isAccountHolder, setIsAccountHolder] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const { t } = useTranslation();

    const [loadDataVersion, setLoadDataVersion] = useState(0)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            // Set if user Is admin To this company 
            const userResponse = await services.getUser({
                tokenProvider: accessTokenProvider
            })
            let userData;
            if (userResponse.success) {
                userData = userResponse.data;
            } else {

                if (userResponse.data.isActionError) {
                    addNotification({
                        text: t(userResponse.data.message),
                        type: 'critical'
                    })
                } else {
                    //  setErrorTemplate(t(userResponse.data.message))
                }
            }


            // get Companydata
            const response = await services.getCompanyByCompanyId({
                tokenProvider: accessTokenProvider,
                companyId: companyId
            })

            if (response.success) {
                const data = response.data;

                setCompanyName(data.name)
                setCompanyData(data)
                setIsAccountHolder(permition.isCompanyAccountHolder(
                {
                    company: data,
                    userId: userData?.id
                }))
                debugger
                setIsAdmin( await permition.isAdminInCompany(
                    {
                        companies: userResponse.data.companies,
                        companyId: companyId
                    }))
                setIsLoading(false)
            } else {
                if (response.data.isActionError) {

                    if (response.data.status === 404) {
                        response.data.message = 'Account.Message.NotFound'
                        setCriticalError(response.data)
                        setIsLoading(false)
                        return
                    }
                    setCriticalError(response.data)
                } else {
                    setCriticalError(response.data)
                }
            }
            setSubscriptionTabVisible(true)
            setIsLoading(false)
        }
        fetchData()

    }, [loadDataVersion, companyId, t, addNotification])


    //setLoadDataVersion(x => x+1)

    return (
        <>
            <CompanyMasterpage 
                loading={isLoading}
                title={companyName ? companyName: companyNameParam}
                companyId={companyId}
                tabsEnabled={true}
                tabSelectedIndex={CompanyTabs.OldCompany}
                criticalError={criticalError}
                isAccountHolder ={isAccountHolder}
                isAdmin={isAdmin}
            >
                {
                    subscriptionTabVisible ?
                        <Company
                            isAdmin={isAccountHolder}
                            companyData={companyData}
                            isLoading={isLoading}
                            setIsLoading={(value) => setIsLoading(value)}
                            setCriticalError={setCriticalError}
                            loadDataCallback={() => setLoadDataVersion(x => x + 1)}>
                        </Company>
                        :
                        <div>
                        </div>
                }
            </CompanyMasterpage>
        </>
    )
}