import styled from "@emotion/styled"
import { useMemo } from "react";
import { useThemeContext } from "@design-system/lumen"
import {getFontMD} from "@design-system/lumen/dist/styles/classes";

export default function UserItem({ desc, name, useMargins }) {
    const { theme } = useThemeContext();
    const {Description} = useMemo(() => UserItemStyle(theme), [theme]);

    return (
        <Description useMargins={useMargins}> {desc} {name}
        </Description>
    )
}

function UserItemStyle({ color_ambient_05 }) {
    const { theme } = useThemeContext();
    const font_md = getFontMD(theme);
    const Description = styled.div`
        color: ${color_ambient_05};
        ${font_md};
        margin-top: ${props => (props.useMargins ? '12px' : '0')};
        margin-bottom: ${props => (props.useMargins ? '24px' : '0')};
    `
    return { Description }
}
