import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import {
    Overlay,
    HorizontalFlow,
    Input,
    useNotificationsContext
} from "@design-system/lumen";
import services from "../../../../services/generic/services";
import notificationsHelper from "../../../../utils/notificationsHelper";


export default function EditName({ companyData, visible, setCriticalError, onClose, loadDataCallback }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayVisible, setOverlayVisible] = useState(false)
    const [name, setName] = useState([])

    useEffect(() => {
        setNotifications('')
        setNotificationsType('')
        setOverlayVisible(visible)
        setName(companyData?.name)
    }, [companyData, visible])

    async function editCompanyName() {
        setIsLoading(true)
       const companyDataEdit = {
            name: name,
            //this data must be removed server Sync
            address: "1234",
            postalCode: "4600-730",
            city: "Porto",
            countryCode:"PT",
            vat: "1234"
        }
        const response = await services.editCompany({
            tokenProvider: accessTokenProvider,
            companyId: companyData.companyId,
            data: companyDataEdit
        });

        if (response.success) {
            setNotifications(t('Account.Company.CompanySection.EditName.Message.Success'))
            setNotificationsType('success')

            addNotification(notificationsHelper.success({text:t('Account.Company.CompanySection.EditName.Message.Success')}))
            loadDataCallback()
            onClose()
        } else {
            if (response.data.isActionError) {
                if (response?.data?.status === 404) {
                    setNotifications(t('Account.Company.CompanySection.EditName.Message.' + response?.data?.message))
                    setNotificationsType('error')
                } else {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    }

    function cancel() {
        setNotifications("")
        setNotificationsType("")
        setOverlayVisible(false);
        onClose()
    }

    return (
        <>
            <Overlay
                actions={[
                    {
                        action: function () { editCompanyName() },
                        title: t('Common.Actions.Save'),
                        type: 'primary',
                        active: true
                    },
                    {
                        action: function () { cancel(); },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost'
                    }
                ]}
                active={true}
                allowCloseWithActions={false}
                centerContentVertically={true}
                error={false}
                errorTemplate="Something went wrong. Please try again later."
                loading={isLoading}
                notifications={notifications}
                notificationsType={notificationsType}
                title={t('Account.Company.CompanySection.EditName.Title')}
                visible={overlayVisible}
            >

                <HorizontalFlow
                    maxItemsPerLine="1"
                    useBottomMargin={false}
                    useTopMargin={false}
                >
                    <Input
                        active={true}
                        forceValidations={false}
                        label={t('Account.Company.CompanySection.EditName.Name')}
                        loading={isLoading}
                        setValue={setName}
                        placeholder={t('Account.Company.CompanySection.EditName.NamePlaceholder')}
                        required={true}
                        requiredMessage={t('Account.Company.CompanySection.EditName.MandatoryNamePlaceholder')}
                        showValidationError={true}
                        type="text"
                        value={name}
                        width="100%"

                    />
                </HorizontalFlow>

            </Overlay>
        </>
    )
}
