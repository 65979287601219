import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
    useThemeContext,
    useNotificationsContext
} from "@design-system/lumen";

import services from "../../../services/generic/services";
import accessTokenProvider from '../../../services/generic/session'

import styled from "@emotion/styled";
import { useCallback } from "react";
import notificationsHelper from "../../../utils/notificationsHelper";
import CSMasterpage from "../../../components/CSMasterpage";
import PageOverlay from "../../../components/PageOverlay";

export default function PaddleCheckout({
    location
}) {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory()

    const { addNotification } = useNotificationsContext()
    const [criticalError, setCriticalError] = useState('');
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);

    const paddle = window.Paddle;
    const { companyName, company, planId, paymentUrl } = location.state;

    const { t } = useTranslation();

    const handleWithError = (error) => {
        if (error?.isActionError) {
            addNotification({
                text: t(error?.message),
                type: 'critical'
            })
        } else {
            setCriticalError(error.data)
        }
    };

    async function checkoutComplete() {
        const response = await services.updateSubscription({
            tokenProvider: accessTokenProvider,
            companyId: company.companyId
        });
        if (response.success) {
            history.push({ pathname: '/' });
            addNotification(notificationsHelper.success({ text: 'Thank you for your purchase! An email has been sent to you' }));
        } else {
            // TODO handle Error
        }
    }
    
    async function checkoutStarted(checkoutId) {
        setIsLoadingPayment(true);
        const response = await services.createSubscription({
            tokenProvider: accessTokenProvider,
            companyId: company?.companyId,
            data: { planId: planId, paymentId: checkoutId }
        });
        // TODO handle this
        if (response.success) {

        } else {
            console.log(response);
        }
        setIsLoadingPayment(false);
    }    

    function openCheckout() {
        paddle.Checkout.open({
            //override: 'https://sandbox-buy.paddle.com/checkout/'+checkoutId, // This work for completing the transaction, but the callbacks won't work
            //override: `https://sandbox-checkout.paddle.com/checkout/custom/${checkoutId}`,
            override: paymentUrl,
            method: 'inline',
            frameTarget: 'checkout-container',
            frameInitialHeight: 416, 
            frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
            completeCallback: checkoutComplete,
            checkoutCallback: checkoutStarted
        });
    }

    useEffect(() => {    
        openCheckout();
    }, []);


    return (
        <>
            <CSMasterpage
                // breadcrumb={[
                //     {
                //         pathname: '/',
                //         title: t('Breadcrumb.AppTitle')
                //     },
                //     {
                //         pathname: '/new-account/plan/' + planId,
                //         title: t('Breadcrumb.NewPremiumAccount')
                //     }
                // ]}
                title={t('NewAccount.Payment.Title')}
                loading={isLoading}
                titleNote={t('NewAccount.Payment.Step4')}
                criticalError={criticalError}
            >
        
                <div className="checkout-container"></div>
                <PageOverlay isVisible={isLoadingPayment} message='Contacting server, please wait a moment...'/>
            </CSMasterpage>

        </>
    )
}