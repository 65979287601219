import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Overlay,
    useNotificationsContext
} from "@design-system/lumen";
import notificationsHelper from "../../../../utils/notificationsHelper";


export default function EditBillingInfo({ companyData, visible, setCriticalError, onClose, actionName, url}) {
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const paddle = window.Paddle;

    useEffect(() => {
        setNotifications('')
        setNotificationsType('')
        // In this way it is not possible implement, we must connect the cancel  by API 
       // https://developer.paddle.com/guides/how-tos/subscriptions/cancel-and-pause

        paddle.Checkout.open({
            method: 'inline',
            override: url, 
            allowQuantity: false,
            disableLogout: true,
            frameTarget: 'edit-container', // The className of your checkout <div>
            frameInitialHeight: 416,
            frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' ,   // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
            successCallback: (data, err) => {
                addNotification(notificationsHelper.success({ text: t('NewAccount.Company.Create.Message.Success') }))
                onClose()
            }      
        });
    }, [companyData, visible])

  
    return (
        <>
            <Overlay
                active={true}
                allowCloseWithActions={true}
                centerContentVertically={true}
                error={false}             
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={actionName}
                visible={visible}
                onClose={()=> onClose()}
                height={'800px'}
            >
                   <div className="edit-container"></div>   

            </Overlay>
        </>
    )
}
