import { useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Dropdown, useThemeContext, useNotificationsContext, CardView, Button } from "@design-system/lumen";

import services from "../../../services/generic/services";
import accessTokenProvider from '../../../services/generic/session'
import CSMasterpage from "../../../components/CSMasterpage";
import Checkbox from "@design-system/lumen/dist/Checkbox";
//import CardView from "@design-system/lumen/dist/Listings/CardView";
import features from "../../../utils/getPlanFeatures";
import Svg from "@design-system/lumen/dist/Svg";
//import Button from "@design-system/lumen/dist/Buttons/Button";
import notificationsHelper from "../../../utils/notificationsHelper";
import PageOverlay from "../../../components/PageOverlay";
import NewAccountPageStyle from "./NewAccountPageStyle";

export default function NewAccountLegalPage({
    location
}) {
    const { theme } = useThemeContext()
    const { color_brand_primary_base } = theme
    
    const {Action, Right, Left, NewAccount, CheckBoxPadding, LableTerms, Plan,Link} = useMemo(() => NewAccountPageStyle(theme), [theme]);

    const { name, planId, address, countryCode,  vat,  phone, city, postalCode } = location.state;

    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory()
    const [planList, setPlanList] = useState([])
    const { addNotification } = useNotificationsContext()
    const [criticalError, setCriticalError] = useState('');
    const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
    const [isDataProtectionChecked, setIsDataProtectionChecked] = useState(false);

    const { t } = useTranslation();
    const [plans, setPlans] = useState([]);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [planIdChoosed, setPlanIdChoosed] = useState("")

    useEffect(() => {
        // Enables the submit button if the form is valid
        setIsFormValid(name && name.length > 0 && isTermsAndConditionsChecked && isDataProtectionChecked);
        setIsFormDirty(true);
    }, [isTermsAndConditionsChecked, isDataProtectionChecked, name])


    const handleWithError = (error) => {
        if (error?.isActionError) {
            addNotification({
                text: t(error?.message),
                type: 'critical'
            })
        } else {
            setCriticalError(error.data)
        }
    };

    useEffect(() => {
      
        fetchData()
    }, [])



    
    function handlePlanResponse(planResponse) {
        if (planResponse.success) {
            setPlanList(planResponse.data.plans.map((item, index) => ({
                selected: Number(planId) === Number(item.planId),
                plan: item,
                text: item.name
            })))
            features.planByPlanId({
                planId: planId,
                setIsLoading: (val) => setIsLoading(val),
                setPlans: (val) => setPlans(val),
                addNotification: (val) => addNotification(val),
                setCriticalError: (val) => setCriticalError(val),
                t: t,
                isLoggdIn: true,
                tokenProvider: accessTokenProvider
            })     
            setPlanIdChoosed(planId)
        } else {
            handleWithError(planResponse?.data)
        }
    
    }

    function fetchData() {
        setIsLoading(true);
        setIsFormDirty(false);
        setIsDataLoaded(false);
        Promise.all([
            services.getPlans({ tokenProvider: accessTokenProvider }),
        ]).then(results => {
            let [planResponse] = results;
            handlePlanResponse(planResponse);
       
            //setIsAccountHolder(permition.isCompanyAccountHolder({ company: companyResponse?.data, userId: userResponse?.data?.id }));
        }).catch((err) => {
            console.error(err);
            //TODO do something here
        }).finally(() => {
           
            setIsLoading(false);
            setIsDataLoaded(true);
        });
    }


    async function createCompany() {
        if (name.length === 0) {
            addNotification({
                text:  t('NewAccount.Company.Form.NameValidation'),
                type: 'warning',
                duration: 8
            })
            return
        }


        if (!isTermsAndConditionsChecked || !isDataProtectionChecked) {
            addNotification({
                text: t('NewAccount.Legal.Form.TermsAndConditions.warning'),
                type: 'warning',
                duration: 8
            })
            return
        }
        
        setIsLoading(true)
        //has to be hardcode because i dont request filds on front-end
        const companyData = {
            name: name,
            address: address,
            countryCode: countryCode,
            vat: vat,
            phone: phone,
            city: city,
            postalcode: postalCode
        }
   
        const response = await services.createCompany({
            tokenProvider: accessTokenProvider,
            data: companyData
        });

        if (response.success) {
            addNotification(notificationsHelper.success({ text: t('NewAccount.Company.Create.Message.Success', { name: name }) }))
            history.push({ pathname: `/`})

            //startPayment(response.data);
        } else {
            handleWithError(response?.data)
        }
        setIsLoading(false)
}

    async function startPayment(company) {
        setIsLoadingPayment(true);

        const response = await services.getPaylink({
            tokenProvider: accessTokenProvider,
            planId: planId
        });

        if (response.success) {
            let createAccountMetadata = {
                company : company,
                companyName : name,
                planId: planId,
                paymentUrl: response.data
            };
            history.push({ pathname: `/new-account/checkout`, state: createAccountMetadata})
        } else {
            handleWithError(response?.data)
        }
        setIsLoadingPayment(false);
    }

    async function selectPlan({ plan }) {
        setPlanIdChoosed(planId)
        features.planByPlanId({
            planId: plan.planId,
            setIsLoading: (val) => { setIsLoading(val) },
            setPlans: (val) => { setPlans(val) },
            addNotification: (val) => { addNotification(val) },
            setCriticalError: (val) => { setCriticalError(val) },
            t: t,
            isLoggdIn: true,
            tokenProvider: accessTokenProvider
        })
    }

    function termLine({ text1, textLink, link, text2 }) {
        return (
            <>
                {text1}
                <Link href={link} target="_blank" >
                    <span aria-hidden="true"></span> {textLink}
                </Link>
                <Svg icon="open" style={{ width: '20px', height: '20px', marginLeft: '4px', verticalAlign: 'middle' }} pathStyle={{ fill: color_brand_primary_base }}></Svg>
                {text2}
            </>
        );
    }

    function cancel() {  
        
       if (isDataLoaded ) {
        
        history.push({ pathname: `/`})

       }}

         function back() {  
            if (isDataLoaded ) {
                // The only required field is the company name
               // setIsFormValid(formCompanyName && formCompanyName.length > 0)
               const createAccountMetadata = {
                name: name,
                address: address,
                countryCode: countryCode,
                vat: vat,
                phone: phone,
                city: city,
                postalcode: postalCode
             }
             history.push({ pathname: `/new-account/plan/` +planId, state: createAccountMetadata})
    
            }
        
        }

    return (
        <>
            <CSMasterpage
                // breadcrumb={[
                //     {
                //         pathname: '/',
                //         title: t('Breadcrumb.AppTitle')
                //     },
                //     {
                //         pathname: '/new-account',
                //         title: t('Breadcrumb.NewPremiumAccount')
                //     }
                // ]}
                title={t('NewAccount.Legal.Title')}
               
                titleNote={t('NewAccount.Legal.Step3')}
                criticalError={criticalError}
                footerActions={[
                    {
                      action: () =>  createCompany(),
                      text:  t('NewAccount.Company.IAccept'),
                      type: 'primary',
                      active :isFormValid && isFormDirty
                    },
                    {
                        action:  () =>  cancel(),
                        text:  t('NewAccount.Company.Cancel'),
                        type: 'ghost'
                      },
                    {
                      action: () =>  back(),
                      text:  t('NewAccount.Company.Back'),
                      type: 'ghost'
                    }
                  ]} 
            >

                <NewAccount >
                    <Left>                        
                      {/* <Dropdown
                            active={true}
                            buttonMode={false}
                            defaultValue={null}
                            labelText={t('NewAccount.Legal.Form.SubscriptionPlan')}
                            loading={isLoading}
                            noItemsLabel="No items found"
                            numberOfVisibleOptions={6}
                            openPanelToLeft={false}
                            options={planList}
                            searchLabel={t('NewAccount.Legal.Form.SubscriptionPlanSearch')}
                            searchable={true}
                            selectionCallback={item => selectPlan({ plan: item.plan })}
                            marginBottom="20px"
                        /> */}
                        <Plan>
                            <CardView
                                active={false}
                                addNewItemPosition="none"
                                loading={isLoading}
                                cardsData={plans}
                                alternativeBackground={false}
                                numberOfColumnOverride={1}
                            />
                        </Plan>
                    </Left>
                    <Right>

                    <LableTerms>
                            {t('NewAccount.Legal.Form.AcceptLable')}
                        </LableTerms>
                        <CheckBoxPadding>
                            <Checkbox text={termLine({
                                text1: t('NewAccount.Legal.Form.TermsAndConditions.Text'),
                                link: t('NewAccount.Legal.Form.TermsAndConditions.Link'),
                                textLink: t('NewAccount.Legal.Form.TermsAndConditions.LinkDesc')
                            })}
                                wrap={true}
                                checked={isTermsAndConditionsChecked}
                                onStateChange={(checked) => { setIsTermsAndConditionsChecked(checked)}}>
                            </Checkbox>
                        </CheckBoxPadding>
                        <CheckBoxPadding>
                            <Checkbox text={termLine({
                                text1: t('NewAccount.Legal.Form.DataProtection.Text'),
                                link: t('NewAccount.Legal.Form.DataProtection.Link'),
                                textLink: t('NewAccount.Legal.Form.DataProtection.LinkDesc')
                            })}
                                wrap={true}
                                checked={isDataProtectionChecked}
                                onStateChange={(checked) => { setIsDataProtectionChecked(checked)}}>
                            </Checkbox>
                        </CheckBoxPadding>
                       
                    </Right>
                </NewAccount>              
              
                <PageOverlay isVisible={isLoadingPayment} message='Processing payment link, please wait a moment...'/>
            </CSMasterpage>
        </>
    )
}