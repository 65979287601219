import styled from '@emotion/styled';
import { md_or_larger } from "@design-system/lumen";
import { font_lg } from "@design-system/lumen/dist/styles/classes";

export default function CompanyPageStyle({ density_space_md, color_ambient_02 }) {

    const PlanContainer = styled.div`
        width:294px;
    `

    const Title = styled.div`
        padding-top: ${density_space_md};
        padding-bottom: ${density_space_md};
        color: ${color_ambient_02};
        ${font_lg};
    `

    const ActionButton = styled.div`
        display: flex;
        gap: ${density_space_md};
        flex: 1;
        padding:0;
        margin-left: auto;
        flex-wrap: wrap;
        height: fit-content;

        [role="button"] {
            margin-left: auto !important;
        }
    `

    const NoPaddingScreenData = styled.div`
        [data-testid="data-block"] {
            padding: 0!important;
        }
    `

    const DeletePremiumAccountSection = styled.div`
        width: 100%;
        margin-top: ${density_space_md};
        ${md_or_larger} {
            margin-top: 0;
        }
    `

    return { PlanContainer, Title, DeletePremiumAccountSection, ActionButton, NoPaddingScreenData }
}