import React from "react";
import { Overlay, Text, Checkbox, useNotificationsContext } from "@design-system/lumen"
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import services from "../services/generic/services";
import accessTokenProvider from '../services/generic/session';
import { logoutRedirectUri } from "../config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export default function TermsAndConditionsModal() {

  const { t } = useTranslation();

  const [accept, setAccepted] = useState(true);
  const { addNotification } = useNotificationsContext();
  const { instance } = useMsal()
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [dataProtectionCheckbox, setDataProtectionCheckbox] = useState(false);
  const localAcceptedTermsAndConditionsDate = 'AcceptedTCDate';
  const localDateTimeout = 30 * 1000; // 30 seconds in milliseconds
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {

    async function fetchHasAcceptedTermsAndConditions(){
        var storedDateTime = new Date(localStorage.getItem(localAcceptedTermsAndConditionsDate)).getTime();
        var currentDateTime = new Date().getTime();
        if(currentDateTime - storedDateTime < localDateTimeout) {
            setAccepted(true)
            return
        }
        
        if(!isAuthenticated){
            return
        }

        const response = await services.getUserTermsAndConditionsAcceptance({
            tokenProvider: accessTokenProvider
        })

        if (response.success) {          
            setAccepted(response.data)

            //if the TCs have already been accepted, setup local timeout
            if(response.data){
                var currentDate = new Date().toISOString();
                localStorage.setItem(localAcceptedTermsAndConditionsDate, currentDate);
            }
        } else {
            addNotification({
                text: t(response.data.message),
                type: 'critical'
            })
        }
    }

    fetchHasAcceptedTermsAndConditions()
  }, [])

  async function acceptTerms() {    
    const response = await services.acceptUserTermsAndConditions({
        tokenProvider: accessTokenProvider
    })     
    if (response.success) {     
        setAccepted(response.data)
    } else {
        if (response.data.isActionError) {
            addNotification({
                text: t(response.data.message),
                type: 'critical'
            })
        } else {
            //  setErrorTemplate(t(response.data.message))
        }
    }
}

  async function refuseTerms() {    
    instance.logoutRedirect({
        postLogoutRedirectUri: logoutRedirectUri
    });
}

  return (
    <>
      {accept ? (
        <div></div>
      ) : (
        <Overlay
            allowScroll={false}
            title={t('AcceptTermsAndConditions.Title')}
            onClose={function () { setVisible(false); }}
            visible={true}
            actions={[
                {
                action: function () { acceptTerms() },
                title: t('AcceptTermsAndConditions.Submit'),
                type: 'primary',
                active: termsCheckbox && dataProtectionCheckbox
                },
                {
                action: function () { refuseTerms() },
                title: t('AcceptTermsAndConditions.Refuse'),
                type: 'ghost'
                }
            ]}
            showCloseButton={false}>
            <div>
                <Text text= {t('AcceptTermsAndConditions.Message.Line1')} />
                <br/>
                <br/>
                <Text text= {t('AcceptTermsAndConditions.Message.Line2')} />
                <br/>
                <br/>
                <Checkbox
                active={true}
                checked={termsCheckbox}
                display="inline"
                onStateChange={() => setTermsCheckbox(!termsCheckbox)}
                />
                <Text text= {t('AcceptTermsAndConditions.Message.Accept')} /> 
                <a target="_blank" href="https://www.tridonic.fr/download/eula/EULA_V1_1.html">
                    {t('AcceptTermsAndConditions.Message.TermsAndConditions')}
                </a>
                <br/>
                <br/>
                <Checkbox
                active={true}
                checked={dataProtectionCheckbox}
                display="inline"
                onStateChange={() => setDataProtectionCheckbox(!dataProtectionCheckbox)}
                />
                <Text text= {t('AcceptTermsAndConditions.Message.Accept')} /> 
                <a target="_blank" href="https://www.tridonic.fr/download/GDPR/cS-EN.html">
                    {t('AcceptTermsAndConditions.Message.DataProtectionPolicy')}
                </a>            </div>
            </Overlay>
      )}

    </>
  );


}

