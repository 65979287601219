import Spinner from "@design-system/lumen/dist/Loadings/Spinner";
import styled from "@emotion/styled";

export default function PageOverlay({
    isVisible,
    message
}) {
    return (
        <PageOverlayContainer isVisible={isVisible}>
            <PageOverlayBackground/>
                <PageOverlayContent>
                    <Spinner size='60px' strokeWidth='6px'></Spinner>
                    <div style={{marginTop:'15px'}}>{message}</div>
                </PageOverlayContent>
        </PageOverlayContainer>
    );
}

const PageOverlayContainer = styled.div`
    display: ${p => p.isVisible ? 'block': 'none'};
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: 100000;
`
const PageOverlayContent = styled.div`
    margin-left: auto;
    margin-top: auto;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);   
    left: 50%;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -55%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 4px;
    background-color: #FFFFFF;
`

const PageOverlayBackground = styled.div`
    -ms-opacity: 0.6;
    background: #444444;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
`