import { useEffect, useState } from "react";
import accessTokenProvider from '../../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../../services/generic/services";
import DeleteConfirmation from "../../../../components/parts/DeleteConfimation";
import ListData from "../../../../components/ListData";
import notificationsHelper from "../../../../utils/notificationsHelper";
import {
    useNotificationsContext,
    color_critical_strong,
    Overlay
} from "@design-system/lumen";


export default function InvitesSent({ visible, isLoading, setIsLoading, invites, setCriticalError, loadDataCallback }) {
    const { t } = useTranslation();
    const { addNotification } = useNotificationsContext()
    const [notifications, setNotifications] = useState('')
    const [notificationsType, setNotificationsType] = useState('')
    const [overlayCancelInviteVisible, setOverlayCancelInviteVisible] = useState(false)
    const [inviteToCancel, setInviteToCancel] = useState({})
    const [invitesSent, setInvitesSent] = useState([])

    useEffect(() => {
        //console.log(Date(invites[0]?.dateExpired))
        setInvitesSent(invites.map((invite, index) => (
            {
                title: invite?.invitedEmail,
                description: invite.companyRoles.map(companyRole => t('PremiumAccounts.CardView.' + companyRole.companyGroupKind)).join(', '),
                invite: invite,
                status: invite?.inviteStatus,
                dateExpired: invite?.dateExpired
            }
        )))
    }, [invites, t])

    async function cancelInviteWarning({ invite, index }) {

        setNotifications('')
        setNotificationsType('')
        setInviteToCancel(invite)
        setOverlayCancelInviteVisible(true)
    }

    function continueAction() {
        setOverlayCancelInviteVisible(false)
    }

    async function resendInvite(invite) {
        let response = await services.resendInvite({
            tokenProvider: accessTokenProvider,
            companyId: invite?.invite?.company?.companyId,
            inviteId: invite?.invite?.inviteId
        });

        if (response.success) {
            setNotifications(t('Account.Users.ResendInvite.Message.Success', { email: invite?.invite?.invitedEmail }))
            setNotificationsType('success')
            addNotification(
                notificationsHelper.success({ text: t('Account.Users.ResendInvite.Message.Success', { email: invite?.invite?.invitedEmail }) }))
        } else {
            if (response.data.isActionError) {
                if (response.data.status === 404) {
                    setNotifications(t('Account.Users.ResendInvite.Message.NotFound'))
                    setNotificationsType('error')
                }
                else {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
    }

    async function cancelInvite() {

        const response = await services.cancelInvite({
            tokenProvider: accessTokenProvider,
            companyId: inviteToCancel?.company?.companyId,
            inviteId: inviteToCancel?.inviteId
        });

        if (response.success) {
            setNotifications(t('Account.Users.CancelInvite.Message.Success', { email: inviteToCancel?.invitedEmail }))
            setNotificationsType('success')
            addNotification(
                notificationsHelper.success({ text: t('Account.Users.CancelInvite.Message.Success', { email: inviteToCancel?.invitedEmail }) }))
            setOverlayCancelInviteVisible(false)
            loadDataCallback()
        } else {
            if (response.data.isActionError) {
                if (response.data.status === 404) {
                    setNotifications(t('Account.Users.CancelInvite.Message.NotFound'))
                    setNotificationsType('error')
                }
                else {
                    setNotifications(t(response?.data?.message))
                    setNotificationsType('error')
                }
            } else {
                setCriticalError(response.data)
            }
        }
        setIsLoading(false)
    }

    function checkInvite(invite){
        let today = new Date();

        //Remove invites that are expired and are a week old
        let finalDate = new Date(invite.dateExpired);
        finalDate.setDate(finalDate.getDate()+7);

        return (invite.status == "Expired" && today < finalDate); 
    }

    return (
        <>
            {visible ?
                <div>
                    <ListData
                        type={"Pending"}
                        title={invitesSent.length > 0 ? t('Account.Users.InvitesSent') : ""}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        data={invitesSent.filter(invite => invite.status == "Sent")}
                        t={t}
                        callbackAction={(item) => resendInvite({ invite: item })}
                        callbackDeleteAction={(item, index) => cancelInviteWarning({ invite: item, index: index })}>
                    </ListData>
                    <ListData
                        type={"Expired"}
                        title={invitesSent.length > 0 ? t('Account.Users.InvitesExpired') : ""}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        data={invitesSent.filter(invite => checkInvite(invite))}
                        t={t}>
                    </ListData>
                </div>
                : <div></div>
            }
            <Overlay
                actions={[
                    {
                        action: function () { cancelInvite() },
                        title: t('Common.Actions.Cancel'),
                        type: 'ghost_semantic'
                    },
                    {
                        action: function () { continueAction() },
                        title: t('Common.Actions.Discard'),
                        type: 'ghost'
                    }
                ]}
                allowCloseWithActions={false}
                centerContentVertically={true}
                loading={false}
                notifications={notifications}
                notificationsType={notificationsType}
                title={t('Account.Users.CancelInvite.Title')}
                visible={overlayCancelInviteVisible}
            >

                <DeleteConfirmation
                    question={t('Account.Users.CancelInvite.Question')}
                >
                </DeleteConfirmation>

            </Overlay>
        </>
    )
}
