
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import accessTokenProvider from '../../../services/generic/session'
import { useTranslation } from "react-i18next";
import services from "../../../services/generic/services";
import CSMasterpage from "../../../components/CSMasterpage";
import notificationsHelper from "../../../utils/notificationsHelper";
import { useNotificationsContext } from "@design-system/lumen";

export default function AcceptInvitePage() {
    const { t } = useTranslation();
    const history = useHistory()
    const { inviteId, companyId } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotificationsContext()
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        async function fetchAcceptInvite() {
            setIsLoading(true)
            let response = await services.acceptInvite({
                tokenProvider: accessTokenProvider,
                companyId: companyId,
                inviteId: inviteId
            });
            
            if (response.success) {
                addNotification(notificationsHelper.success({text:t('AcceptInvite.Message.Success')}))
                history.push({ pathname: `/account/${companyId}/storage` })
            } else {
                if (response?.data?.isActionError) {
                    if (response?.data?.status === 403) {
                        setErrorTitle('AcceptInvite.Error.InvalidEmail.Title');
                        setErrorMessage('AcceptInvite.Error.InvalidEmail.Message');
                    } else {
                        // Set default error title and message
                        setErrorTitle('Common.Error.Messages.DefaultError');
                        setErrorMessage('Common.Error.Messages.DefaultErrorDesc');
                    }
                }
            }
            setIsLoading(false)
        }
        fetchAcceptInvite()

    }, [])

    return (
        <>
            <CSMasterpage
                criticalError={{title: t(errorTitle), message: t(errorMessage)}}
                title={t('Breadcrumb.AcceptInvite')}
                loading={isLoading}
                breadcrumb={[
                    {
                        pathname: '/',
                        title: t('Breadcrumb.AppTitle')
                    },
                    {
                        title: t('Breadcrumb.AcceptInvite')
                    }
                ]}
            >
            </CSMasterpage>
        </>
    )
}