import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from "react-router";
import { HorizontalFlow, Input, Dropdown, Button, useNotificationsContext, useThemeContext } from '@design-system/lumen';
import { useTranslation } from 'react-i18next';
import accessTokenProvider from '../../../../services/generic/session'
import CompanyTabs from '../../../../enum/CompanyTabs';
import services from '../../../../services/generic/services';
import permition from '../../../../services/generic/permition';
import CompanyMasterpage from '../../../../components/CompanyMasterpage';
import SettingsTabPageStyle from './SettingsTabPageStyle';
import EditAccountHolder from './EditAccountHolder';
import DeletePremiumAccount from '../Company/DeletePremiumAccount';
import notificationsHelper from '../../../../utils/notificationsHelper';
import jsonData from './../../../../utils/PostalCode/postal-codes.json';

export default function SettingsTabPage({

}) {
    const location = useLocation();
    const companyNameParam = location.state?.companyName;
    const history = useHistory();
    const { addNotification } = useNotificationsContext();

    const { t } = useTranslation();
    const {theme} = useThemeContext();
    const { EmptyDiv } = useMemo(() => SettingsTabPageStyle(theme), [theme]);
    const [isLoading, setIsLoading] = useState(false);
    const [criticalError, setCriticalError] = useState('');
    const [overlayEditVisible, setOverlayEditVisible] = useState(false);
    const [overlayDeletePremiumVisible, setOverlayDeletePremiumVisible] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    // Data fetched from server
    const [companyData, setCompanyData] = useState();
    const [companyEnable, setCompanyEnable] = useState(false);
    const [userData, setUserData] = useState();
    const [countriesData, setCountriesData] = useState();

    const { companyId } = useParams();
    const [isAccountHolder, setIsAccountHolder] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    
    const [companyName, setCompanyName] = useState('');

    // Form fields
    const [selectedCountry, setSelectedCountry] = useState();
    const [formCompanyName, setFormCompanyName] = useState();
    const [formCompanyVat, setFormCompanyVat] = useState();
    const [formCompanyPhone, setFormCompanyPhone] = useState();
    const [formCompanyCity, setFormCompanyCity] = useState();
    const [formCompanyAddress, setFormCompanyAddress] = useState();
    const [formCompanyPostalCode, setFormCompanyPostalCode] = useState();

   // Validation fields
    const [formCompanyNameIsValid, setFormCompanyNameIsValid] = useState();
    const [formCompanyVatIsValid, setFormCompanyVatIsValid] = useState();
    const [formCompanyPhoneIsValid, setFormCompanyPhoneIsValid] = useState();
    const [formCompanyCityIsValid, setFormCompanyCityIsValid] = useState();
    const [formCompanyAddressIsValid, setFormCompanyAddressIsValid] = useState();
    const [formCompanyPostalCodeIsValid, setFormCompanyPostalCodeIsValid] = useState();

    function handleUserResponse(userResponse) {
        if (userResponse.success) {
            setUserData(userResponse.data);
        } else {
            if (userResponse.data.isActionError) {
                addNotification({ text: t(userResponse.data.message), type: 'critical' })
            } else {
                setCriticalError(userResponse.data);
            }
        }
    }

    function handleCompanyResponse(companyResponse) {
        let companyDataResponse = companyResponse.data;
        if (companyResponse.success) {
            setCompanyData(companyDataResponse);
        } else {
            if (companyDataResponse.isActionError) {
                if (companyDataResponse.status === 404) {
                    companyDataResponse.message = 'Account.Message.NotFound'
                }
                setCriticalError(companyDataResponse);
            } else {
                setCriticalError(companyDataResponse);
            }
        }
    }

    function handleCountriesResponse(countriesResponse) {
        let countriesDataResponse = countriesResponse?.data;
        if (countriesResponse.success) {
            let countriesData = [null].concat(countriesDataResponse);
            setCountriesData(countriesData);
        } else {
            setCriticalError(countriesDataResponse);
        }
    }

    function fetchData() {

        setIsLoading(true);
        setIsFormDirty(false);
        setIsDataLoaded(false);
        Promise.all([
            services.getUser({ tokenProvider: accessTokenProvider }),
            services.getCompanyByCompanyId({ tokenProvider: accessTokenProvider, companyId: companyId }),
            services.getCountries({ tokenProvider: accessTokenProvider })
        ]).then(results => {
            let [userResponse, companyResponse, countriesResponse] = results;
            handleUserResponse(userResponse);
            handleCompanyResponse(companyResponse);
            handleCountriesResponse(countriesResponse);
            setIsAccountHolder(permition.isCompanyAccountHolder({ company: companyResponse?.data, userId: userResponse?.data?.id }));
            setIsAdmin( permition.isAdminInCompany(
                {
                    companies: userResponse.data.companies,
                    companyId: companyId
                }))

        }).catch((err) => {
            console.error(err);
            //TODO do something here
        }).finally(() => {
            setIsLoading(false);
            setIsDataLoaded(true);
        });
        
    }

    async function submitForm() {
        setIsLoading(true);



        let companyForm = {
            countryCode: selectedCountry,
            name: formCompanyName,
            vat: formCompanyVat,
            phone: formCompanyPhone,
            city: formCompanyCity,
            address: formCompanyAddress,
            postalCode: formCompanyPostalCode
        };

        let response = await services.editCompany({ tokenProvider: accessTokenProvider, companyId: companyData.companyId, data: companyForm });
        if (response.success) {
            addNotification(notificationsHelper.success({text:t('Account.Company.CompanySection.EditName.Message.Success')}));
            setIsFormDirty(false);
            setCompanyData(response.data);
        } else {
            addNotification({ text: t(response?.data?.message), type: 'critical' });
        }
        setIsLoading(false);    
    }

    useEffect(() => {
        fetchData();
        /*const loadedData = JSON.stringify(jsonData);
        setPostalCodeByCountryISOCode(JSON.parse(loadedData))
         setVatRegExp("^{1,10}$");
         console.log("vat", vatRegExp); */
    }, []);

    useEffect(() => {
        if (companyData) {
            setCompanyName(companyData.name);
            setCompanyEnable(companyData.enabled)
            setFormCompanyName(companyData.name);
            setFormCompanyVat(companyData.vat)
            setFormCompanyPhone(companyData.phone);
            setSelectedCountry(companyData.countryCode);
            setFormCompanyCity(companyData.city);
            setFormCompanyAddress(companyData.address);
            setFormCompanyPostalCode(companyData.postalCode);
        }
    }, [companyData]);

    useEffect(() => {
        

            // The only required field is the company name
           // setIsFormValid(formCompanyName && formCompanyName.length > 0)
           //debugger
            if(
               formCompanyNameIsValid&&
               formCompanyVatIsValid && 
               formCompanyPhoneIsValid&&
               formCompanyCityIsValid&&
               formCompanyAddressIsValid&&
               formCompanyPostalCodeIsValid
               ){
                setIsFormValid(true);
               }else{
                setIsFormValid(false);
               }
               setIsFormDirty(true);

        
    }, [ formCompanyName,formCompanyNameIsValid,formCompanyVat, formCompanyVatIsValid, formCompanyPhone, formCompanyPhoneIsValid,formCompanyAddress, formCompanyAddressIsValid, formCompanyAddressIsValid, formCompanyPostalCode, formCompanyPostalCodeIsValid, selectedCountry]);
    
/*
    useEffect(() => {
        // Data was already fetched
        if (isDataLoaded) {
            const country = postalCodeByCountryISOCode.filter(country => {
                return country.ISO === selectedCountry;
              });
            
              if(country.length>0){
                setVatRegExp(country[0]);
                console.log("vatChanged", vatRegExp); 
              }
             
           
        }
    }, [selectedCountry]);
    
*/

    return (

        
        <CompanyMasterpage
            
            footerActions={[{
                action: () => submitForm(),
                text: t('Account.Company.CompanySection.Save'),
                type: 'primary',
                loading: isLoading,
                active: isAccountHolder && isFormDirty && isFormValid
            }, {
                action: () => fetchData(),
                text: t('Account.Company.CompanySection.Cancel'),
                type: 'ghost',
                loading: isLoading,
                active: isAccountHolder && isFormDirty
            }, {
                action: () => setOverlayDeletePremiumVisible(true),
                text: t('Account.Company.DeletePremiumAccountSection.Title'),
                type: 'ghost_semantic',
                loading: isLoading,
                active: isAccountHolder
            }]}
            loading={isLoading}
            title={companyName ? companyName : companyNameParam}
            companyId={companyId}
            companyEnable={companyEnable}
            tabsEnabled={true}
            tabSelectedIndex={CompanyTabs.Settings}
            criticalError={criticalError}
            isAccountHolder={isAccountHolder}
            isAdmin={isAdmin}
            >
            {
                
                <>
                    {/* Form */}
                    <HorizontalFlow
                        useBottomMargin={false}
                        maxItemsPerLine="4"
                        useTopMargin={false}
                        width="100%">
                        <div>
                            <Input
                            active={false}
                            label={t('Account.Company.CompanySection.Administrator')}
                            loading={isLoading}
                            required={true}                         
                            showValidationError={false}
                            type="text"
                            value={companyData?.accountHolder?.name ? companyData.accountHolder.name : ''}
                        />
                        </div>

                        

                        <Input
                            active={false}
                            label={t('Account.Company.CompanySection.AdministratorEmail')}
                            loading={isLoading}
                            required={true}
                            requiredMessage={t('NewAccount.Company.Form.NameRequired')}
                            showValidationError={false}
                            type="text"
                            value={companyData?.accountHolder?.email}
                        />
                        <EmptyDiv />
                        <Button
                            active={isAccountHolder}
                            loading={isLoading}
                            text={t('Account.Company.CompanySection.EditAccountHolderEmail.Title')}
                            style={{ marginLeft: 'auto' }}
                            onClick={() => setOverlayEditVisible(true)} />
                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.Name')}
                            loading={isLoading}
                            setValue={setFormCompanyName}
                            placeholder={t('NewAccount.Company.Form.NamePlaceholder')}
                            required={true}
                            forceValidations={false}
                            validationRegExpString="^[a-zA-Z0-9\u4e00-\u9fa5\s]{1,30}$"
                            validationErrorMessage={t('NewAccount.Company.Form.NameValidationErrorMessage')}
                            requiredMessage={t('NewAccount.Company.Form.NameRequired')}
                            type="text"
                            value={formCompanyName}
                            min={1}
                            maxLength={30}
                            validationCallback={(valid, text) => { setFormCompanyNameIsValid(valid); console.log('validation formCompanyNameIsValid:', valid, text) }}
                            textInputMode=""
                            dataTestId="company_Name"
                        />

                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.Vat')}  //"Fiscal number/VAT *"
                            loading={isLoading}
                            setValue={setFormCompanyVat}
                            placeholder={t('NewAccount.Company.Form.VatPlaceholder')}
                            required={false}
                            forceValidations={true}
                            showValidationError={!formCompanyVatIsValid}
                            validationRegExpString="^[a-zA-Z0-9\s]{0,20}$"
                            validationErrorMessage={t('NewAccount.Company.Form.VatValidationErrorMessage')}
                            type="text"
                            value={formCompanyVat}
                            width="100%"
                            maxLength={20}
                            validationCallback={(valid, text) => { setFormCompanyVatIsValid(valid); console.log('validation formCompanyVatIsValid:', valid, text) }}
                            dataTestId="company_Vat"
                        />

                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.Phone')}
                            loading={isLoading}
                            setValue={setFormCompanyPhone}
                            placeholder={t('NewAccount.Company.Form.PhonePlaceholder')}
                            required={false}
                            showValidationError={false}
                            forceValidations={formCompanyPhone !== undefined}
                            validationRegExpString="^(?:\+[0-9]{1,}(?:[0-9\- ]{4,})+[0-9])?$"
                            validationErrorMessage={t('NewAccount.Company.Form.PhoneValidationErrorMessage')}
                            type="int"
                            value={formCompanyPhone}
                            width="100%"
                            margin="10px"
                            maxLength={20}
                            validationCallback={(valid, text) => { setFormCompanyPhoneIsValid(valid); console.log('validation formCompanyPhoneIsValid:', valid, text) }}
                            dataTestId="company_Phone"
                        />
                        <EmptyDiv />
                       
                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.Address')}
                            loading={isLoading}
                            setValue={setFormCompanyAddress}
                            placeholder={t('NewAccount.Company.Form.AddressPlaceholder')}
                            required={false}
                            showValidationError={false}
                            forceValidations={true}
                            validationRegExpString="^.{0,50}$"
                            validationErrorMessage={t('NewAccount.Company.Form.AddressValidationErrorMessage')}
                            type="text"
                            value={formCompanyAddress}
                            width="100%"
                            maxLength={50}
                            validationCallback={(valid, text) => { setFormCompanyAddressIsValid(valid); console.log('validation formCompanyAddressIsValid(:', valid, text) }}
                            dataTestId="company_Address"
                        />

                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.PostalCode')}
                            loading={isLoading}
                            setValue={setFormCompanyPostalCode}
                            placeholder={t('NewAccount.Company.Form.PostalCodePlaceholder')}
                            required={false}
                            showValidationError={false}
                            forceValidations={true}
                            // validationRegExpString= {vatRegExp.Regex}
                            validationRegExpString="^.{0,10}$"
                            validationErrorMessage={t('NewAccount.Company.Form.PostalCodeValidationErrorMessage')}
                            type="text"
                            value={formCompanyPostalCode}
                            width="100%"
                            maxLength={10}
                            validationCallback={(valid, text) => { setFormCompanyPostalCodeIsValid(valid); console.log('validation formCompanyPostalCodeIsValid:', valid, text) }}
                            dataTestId="company_PostalCode"               
                        />

                        <Input
                            active={true}
                            label={t('NewAccount.Company.Form.City')}
                            loading={isLoading}
                            setValue={setFormCompanyCity}
                            placeholder={t('NewAccount.Company.Form.CityPlaceholder')}
                            required={false}
                            showValidationError={false}
                            forceValidations={true}
                            validationRegExpString="^.{0,50}$"
                            validationErrorMessage={t('NewAccount.Company.Form.CityValidationErrorMessage')}
                            type="text"
                            value={formCompanyCity}
                            width="100%"
                            maxLength={50}
                            validationCallback={(valid, text) => { setFormCompanyCityIsValid(valid); console.log('validation formCompanyCityIsValid:', valid, text) }}
                            dataTestId="company_City"
                        />
                       <div>
                            <Dropdown
                                active={true}
                                buttonMode={false}
                                defaultValue={null}
                                labelText={t('NewAccount.Company.Form.Country')}
                                loading={isLoading}
                                numberOfVisibleOptions={3}
                                openPanelToLeft={false}
                                options={
                                    countriesData?.map((item) => ({
                                        selected: selectedCountry === item,
                                        countryCode: item,
                                        text: t('Country.' + item)
                                    }))
                                }
                                placeholder={t('NewAccount.Company.Form.CountryPlaceholder')}
                                searchLabel="Search"
                                searchable={true}
                                selectionCallback={item => setSelectedCountry(item.countryCode)}
                            />
                        </div>
                    </HorizontalFlow>

                    {/* Overlays */}
                    {overlayEditVisible ?
                        <EditAccountHolder companyData={companyData}
                            overlayVisible={overlayEditVisible}
                            setCriticalError={setCriticalError}
                            onClose={() => setOverlayEditVisible(false)}
                            loadDataCallback={fetchData}
                        ></EditAccountHolder> 
                        : null}
                    

                    {overlayDeletePremiumVisible ?
                        <DeletePremiumAccount companyData={companyData}
                            overlayVisible={overlayDeletePremiumVisible}
                            onClose={() => setOverlayDeletePremiumVisible(false)}
                            loadDataCallback={() => history.push('/')}
                        ></DeletePremiumAccount>
                       :null} 
                </>
            }
        </CompanyMasterpage>
    )
}