
/* eslint-disable react/prop-types */
import React from 'react'
//import Button from '@design-system/lumen/dist/Buttons/Button'
import { Button } from "@design-system/lumen";
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useState } from "react";
import ErrorTemplateStyle from './ErrorTemplateStyle';
import { HOME_PAGE_PATH } from '../../pages/Pages';

export default function ErrorTemplate({ error }) {
    const history = useHistory()
    const location = useLocation();  
    const { t } = useTranslation();

    const [{Contents, Action, Error, Message, Title, Desktop, Mobile }, ] = useState(ErrorTemplateStyle())

    function navigateToHomePage () {
        if (location.pathname === HOME_PAGE_PATH) {
            // Forces page reload
            history.go();
        } else {
            history.push({ pathname: HOME_PAGE_PATH })
        }
    }

    return (
        <Error data-testid="delete-confirmation">
            <Mobile>
                <Contents>
                    <Title>{t(error?.title)}</Title>
                    <Message>{t(error?.message)}</Message>
                    <Button active={true}
                        type="primary"
                        text={t('Common.Error.Action')}
                        onClick={() => navigateToHomePage()}>
                    </Button>
                </Contents>
            </Mobile>
            <Desktop>
                <Contents>
                    <Title>{t(error?.title)}</Title>
                    <Message>{t(error?.message)}</Message>
                    <Action>
                        <Button active={true}
                            type="primary"
                            text={t('Common.Error.Action')}
                            onClick={() => navigateToHomePage()}>
                        </Button>
                    </Action>
                </Contents>
            </Desktop>
        </Error>
    )
}