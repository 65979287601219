import { useState } from "react";
import {
    Masterpage,
} from "@design-system/lumen";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { loginRequest } from "../../../config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router";
import PlansFeatures from "../../../components/PlansFeatures";

export default function Plans() {
    const isAuthenticated = useIsAuthenticated();
    const { t } = useTranslation();
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [criticalError, setCriticalError] = useState('');
    const history = useHistory()

    function handleWithLang(langCode) {
        i18next.changeLanguage(langCode);
    }

    function handleLogin() {
        instance.loginRedirect(loginRequest);
    }

    function goToNewAccountPage({ item }) {
        history.push({ pathname: '/new-account/plan/' + item.id })
    }

    return (
        <>
            <Masterpage
                // breadcrumb={[
                //     {
                //         pathname: '/',
                //         title: t('Breadcrumb.AppTitle')
                //     },
                //     {
                //         title: t('Breadcrumb.NewPremiumAccount')
                //     }
                // ]}
                loading={isLoading}
                headerActions={!isAuthenticated ? [
                    {
                        icon: 'globe',
                        options: [{
                            actionCallback: () => handleWithLang("en"),
                            disabled: false,
                            text: t('Header.Language.English'),
                            selected: i18n.language === 'en'
                        },
                        {
                            actionCallback: () => handleWithLang("de"),
                            disabled: false,
                            text: t('Header.Language.German'),
                            selected: i18n.language === 'de'
                        }
                        ]
                    }
                ] : null}
                headerActionsAlignIconLeft={false}
                leadingAction={() => handleLogin()}
                leadingActionIcon="sm-circle"
                leadingActionIconColor="#3C852F"
                leadingActionTitle={t('Plan.Page.LeadingAction')}
                showHeaderActions={true}
                title={t('Plan.Page.Title')}
                titleNote={t('Plan.Page.TitleNote')}
                criticalError={criticalError}

            >
                <div>
                    <PlansFeatures detailActionPlan={goToNewAccountPage}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setCriticalError={setCriticalError}
                        isLoggdIn = {false}
                    ></PlansFeatures>
                </div>
            </Masterpage>
        </>
    )
}